import { get, parseResponse } from '../core/utils/network-requests';
import { URLS } from '../constants';

const getEntities = async sessionToken => {
  const url = URLS.ENTITIES;

  const response = await get({
    url,
    headers: {
      'session-token': sessionToken,
    },
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const entitiesApi = {
  getEntities,
};

export default entitiesApi;
