const zeroFrom = (num, length) => {
  if (typeof num !== 'number') {
    return '';
  }
  const prefixLength = length - `${num}`.length;
  const prefix = prefixLength ? new Array(prefixLength).fill('0').join('') : '';

  return `${prefix}${num}`;
};

export const getDateTime = v => {
  const date = new Date(v);
  const dd = zeroFrom(date.getDate(), 2);
  const mm = zeroFrom(date.getMonth() + 1, 2);
  const yyyy = date.getFullYear();
  const hh = zeroFrom(date.getHours(), 2);
  const min = zeroFrom(date.getMinutes(), 2);
  const ss = zeroFrom(date.getSeconds(), 2);

  return `${dd}.${mm}.${yyyy} ${hh}:${min}:${ss}`;
};
