import { get } from 'lodash';
import { createSelector } from 'reselect';

import { MODELS, ENTITY_ID } from '../../constants';

const { NETWORKING } = MODELS;

export const getEntitiesData = state => get(state, MODELS.ENTITIES_DATA);

export const getUsersData = state => get(state, MODELS.USERS_DATA);

const createLoadStatusSelector = entityId => state =>
  get(state, `${NETWORKING}.${entityId}LoadStatus`);

const createDataSelector = entityId => state =>
  get(state, `${NETWORKING}.${entityId}Data`);

const createShouldBeLoaded = entityId =>
  createSelector(
    [createLoadStatusSelector(entityId), createDataSelector(entityId)],
    (loadStatus, data) => {
      return !loadStatus || (loadStatus === 2 && !data);
    },
  );

export const getEntitiesShouldBeLoaded = createShouldBeLoaded(
  ENTITY_ID.ENTITIES,
);

export const getUsersShouldBeLoaded = createShouldBeLoaded(ENTITY_ID.USERS);

const getEntityLoadStatus = entityId => state =>
  get(state, `${MODELS.NETWORKING}.${entityId}LoadStatus`, 0);

export const loadStatusSelectorsList = Object.values(ENTITY_ID).map(entityId =>
  getEntityLoadStatus(entityId),
);
