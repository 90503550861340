const { REACT_APP_FEATURE_BY_HOST = 'https://feature.by' } = process.env;

const URLS = {
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  LOGOUT: 'auth/logout',
  USER_UPDATE: 'auth/update',
  USER_PROFILE: 'auth/profile',
  CHANGE_PASSWORD: 'auth/change-password',
  CAPTCHA: '/api/captcha',
  PING: '/api/ping',
  FEATURE_BY_HOST: REACT_APP_FEATURE_BY_HOST,
};

export default URLS;
