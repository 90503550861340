import { connect } from 'react-redux';

import Modal from './Modal';
import { getModalIsOpen } from '../../store/selectors';
import { acRemoveModal } from '../../store/actions';

const mapStateToProps = (state, ownProps) => ({
  isOpen: getModalIsOpen(state, ownProps),
});

const mapDispatchToProps = {
  acRemoveModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
