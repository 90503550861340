import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CrossIcon } from '../../icons';

import styles from './Form.module.scss';

const Form = ({
  children,
  className,
  name,
  withCross,
  onCrossClick,
  ...rest
}) => {
  const ownClassName = classNames(styles['form'], className);

  const handleFormClick = e => {
    e.stopPropagation();
  };

  return (
    <form
      name={name}
      className={ownClassName}
      onClick={handleFormClick}
      {...rest}
    >
      {withCross && (
        <span
          className={styles['cross-wrapper']}
          tabIndex="0"
          role="button"
          onClick={onCrossClick}
        >
          <CrossIcon />
        </span>
      )}
      {children}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  withCross: PropTypes.bool,
  onCrossClick: PropTypes.func,
};

Form.defaultProps = {
  children: null,
  className: '',
  withCross: false,
  onCrossClick: () => {},
};

export default Form;
