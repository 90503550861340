const CAPTCHA_PARAMS = {
  field: {
    width: 128,
    height: 48,
  },
  checkbox: {
    width: 20,
    height: 20,
  },
};

export default CAPTCHA_PARAMS;
