const MODELS = {
  NETWORKING: 'networking',
  ENTITIES_DATA: 'networking.entitiesData',
  USERS_DATA: 'networking.usersData',
  ACTIVE_USER: 'networking.activeUserData',
  USERS: 'users',
  USERS_ACTIVE_LOGIN: 'users.activeUserLogin',
};

export default MODELS;
