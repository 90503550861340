import { ROUTES } from './constants';
import CONTENTS from './constants/contents';
import api from './api';
import NetworkService from './components/services/NetworkService';
import reducer from './store/reducers'
import getMainMenuOptions from './components/core-shared/main-menu-options';
// import GAME_2048 from './components/MainContent';
// import { acChangeCellsInRow, saveCurrentGame, quickSaveCurrentGame, quickLoadCurrentGame } from './store/actions';
import {
  // getIsCurrentGameCompleted,
  loadStatusSelectorsList,
  // saveStatusSelectorsList,
} from './store/selectors';
import { getLocationPathname } from './core/store/selectors/router-selectors';

export default {
  api,
  reducer,
  routes: ROUTES,
  loadStatusSelectorsList,
  saveStatusSelectorsList: [],
  getMainMenuProps: state => ({
    pathname: getLocationPathname(state),
  }),
  mainMenuActions: {
    // setCellsInRow: acChangeCellsInRow,
    // saveCurrentGame,
    // quickSaveCurrentGame,
    // quickLoadCurrentGame,
  },
  getMainMenuOptions,
  contents: CONTENTS,
  networkService: NetworkService,
};
