import { connect } from 'react-redux';

import UsersList from './UsersList';
import {
  getUsersData,
  getActiveUserLogin,
} from '../../../../../store/selectors';
import { acSelectUser } from '../../../../../store/actions';

const mapStateToProps = state => ({
  activeUserLogin: getActiveUserLogin(state),
  usersData: getUsersData(state),
});

const mapDispatchToProps = {
  selectUser: acSelectUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
