import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../../core/components/Modal/Modal';
import Form from '../../../../../core/components/Form';
import FormFieldset from '../../../../../core/components/Form/shared/FormFieldset';
import Legend from '../../../../../core/components/Form/shared/Legend';
import FormToolbar from '../../../../../core/components/Form/shared/FormToolbar';
import FormButton from '../../../../../core/components/Form/shared/FormButton';
import { MODALS_ID } from '../../../../../constants';

import styles from './UserForm.module.scss';
import { getDateTime } from '../../../../../core/utils/date';

const getBooleanString = v => (v === 1 ? 'true' : 'false');

const UserForm = ({ value, isDesktop, changeUserStatus, unselectUser }) => {
  console.log('*** value user', value);

  const fields = useMemo(() => {
    return !value ? null : [
      { id: 'login', label: 'Login' },
      { id: 'email', label: 'Email' },
      { id: 'role', label: 'Role' },
      { id: 'is_blocked', label: 'Is blocked', getValue: getBooleanString },
      { id: 'disabled', label: 'Disabled', getValue: getBooleanString },
      { id: 'create_ts', label: 'Created', getValue: getDateTime },
      { id: 'update_ts', label: 'Updated', getValue: getDateTime },
    ].map(({ id, label, getValue }) => {
      const fieldValue = getValue ? getValue(value[id]) : value[id];

      return (
        <div key={id} className={styles['user-form-row']}>
          <span className={styles['user-form-label']}>{label}</span>
          <span className={styles['user-form-value']}>{fieldValue}</span>
        </div>
      );
    });
  }, [value]);

  const toolbar = useMemo(() => {
    if (!value) return null;

    const isInactive = value.is_blocked || value.disabled;

    return (
      <FormToolbar>
        <FormButton
          className={styles['toolbar-button']}
          label={isInactive ? 'Activate user' : 'Block user'}
          onClick={changeUserStatus}
        />
      </FormToolbar>
    );
  }, [changeUserStatus, value]);

  const userForm = useMemo(() => (
    <section className={styles['user-form-root']}>
      <Form
        className={styles['user-form']}
        name="userForm"
        withCross
        onCrossClick={unselectUser}
      >
        <FormFieldset>
          <Legend>User Form</Legend>
          <section>{fields}</section>
          {toolbar}
        </FormFieldset>
      </Form>
    </section>
  ), [fields, toolbar, unselectUser]);

  if (!value) {
    return null;
  }

  return isDesktop ? (
    userForm
  ) : (
    <Modal
      id={MODALS_ID.USER_FORM}
      className={styles['user-form-modal']}
      isCentered
      closeOnClick
      closeOnEscape
      isOpen={!!value}
      onClose={unselectUser}
    >
      {userForm}
    </Modal>
  );
};

UserForm.propTypes = {
  isDesktop: PropTypes.bool,
  value: PropTypes.shape({
    login: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    is_blocked: PropTypes.number,
    disabled: PropTypes.number,
    create_ts: PropTypes.string,
    update_ts: PropTypes.string,
  }),
  changeUserStatus: PropTypes.func,
  unselectUser: PropTypes.func,
};

UserForm.defaultProps = {
  isDesktop: false,
  value: null,
  changeUserStatus: () => {},
  unselectUser: () => {},
};

export default UserForm;
