import { get } from 'lodash';
import { createSelector } from 'reselect';

import { MODELS } from '../../constants';

export const getTouchScreenDetected = state =>
  get(state, MODELS.TOUCH_SCREEN_DETECTED, false);

const getMediaBreakpoint = breakpoint => ({ media }) =>
  get(media, breakpoint, false);

export const getIsMobile = getMediaBreakpoint('isMobile');
export const getIsTablet = getMediaBreakpoint('isTablet');
export const getIsDesktop = getMediaBreakpoint('isDesktop');

export const getBaseMedia = createSelector(
  [getIsMobile, getIsTablet, getIsDesktop],
  (isMobile, isTablet, isDesktop) => ({ isMobile, isTablet, isDesktop }),
);
