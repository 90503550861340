import { v4 as uuidV4 } from 'uuid';

import { AC_ADD_MESSAGE, AC_REMOVE_MESSAGE } from '../actions/action-types';

const initialState = {
  items: [],
};

const messagesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AC_ADD_MESSAGE:
      return {
        ...state,
        items: [{ ...payload, id: uuidV4() }, ...state.items],
      };

    case AC_REMOVE_MESSAGE:
      return {
        ...state,
        items: state.items.filter(({ id }) => id !== payload),
      };

    default:
      return state;
  }
};

export default messagesReducer;
