import { connect } from 'react-redux';

import Controller from './Controller';
import { acSetTouchScreenDetected } from '../../store/actions';

const mapDispatchToProps = {
  setTouchScreenDetected: acSetTouchScreenDetected,
};

export default connect(null, mapDispatchToProps)(Controller);
