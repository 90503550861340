import { get, parseResponse, put } from '../core/utils/network-requests';
import { URLS } from '../constants';

const getUsers = async sessionToken => {
  const url = URLS.USERS;

  const response = await get({
    url,
    headers: {
      'session-token': sessionToken,
    },
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const getUserByLogin = async (sessionToken, userLogin) => {
  const url = `${URLS.USERS}?l=${btoa(userLogin)}`;

  const response = await get({
    url,
    headers: {
      'session-token': sessionToken,
    },
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const updateUser = async (sessionToken, user) => {
  const url = URLS.USERS;

  const response = await put({
    url,
    headers: {
      'session-token': sessionToken,
    },
    body: user,
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const usersApi = {
  getUsers,
  getUserByLogin,
  updateUser,
};

export default usersApi;
