import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isFilledString } from '../../../core/utils/common-utils';

const NetworkService = ({
  isLogged,
  entitiesShouldBeLoaded,
  getEntities,
  usersShouldBeLoaded,
  getUsers,
  activeUserLogin,
  getActiveUser,
  clearActiveUser,
}) => {
  useEffect(() => {
    if (isLogged && entitiesShouldBeLoaded) {
      getEntities();
    }
  }, [isLogged, entitiesShouldBeLoaded, getEntities]);

  useEffect(() => {
    if (isLogged && usersShouldBeLoaded) {
      getUsers();
    }
  }, [getUsers, isLogged, usersShouldBeLoaded]);

  useEffect(() => {
    if (isFilledString(activeUserLogin)) {
      getActiveUser(activeUserLogin);
    } else {
      clearActiveUser();
    }
  }, [activeUserLogin, clearActiveUser, getActiveUser]);

  return null;
};

NetworkService.propTypes = {
  isLogged: PropTypes.bool,
  entitiesShouldBeLoaded: PropTypes.bool,
  activeUserLogin: PropTypes.string,
  getEntities: PropTypes.func,
  getActiveUser: PropTypes.func,
  clearActiveUser: PropTypes.func,
};

NetworkService.defaultProps = {
  isLogged: false,
  entitiesShouldBeLoaded: false,
  activeUserLogin: '',
  getEntities: () => {},
  getActiveUser: () => {},
  clearActiveUser: () => {},
};

export default NetworkService;
