import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Captcha.module.scss';
import roundedRect from '../../utils/canvas/rounded-rect';

const initialState = {
  x: 0,
  y: 0,
  requestStatus: 0,
  validationMessage: '',
};

const Captcha = ({
  className,
  value,
  fieldWidth,
  fieldHeight,
  checkboxWidth,
  checkboxHeight,
  onChange,
  createCaptcha,
  updateCaptcha,
}) => {
  const [state, setState] = useState({ ...initialState });
  const field = useRef(null);
  const ownClassName = classNames(styles['captcha'], className && className);

  const handleClick = e => {
    if (value.isCompleted === true) {
      return;
    }
    const { clientX, clientY } = e;
    const { top, left } = field.current.getBoundingClientRect();
    const x = clientX - left;
    const y = clientY - top;

    const callback = result => {
      if (result?.isCompleted === true) {
        setState({
          ...state,
          validationMessage: '',
          requestStatus: 2,
        });
        onChange({ ...value, isCompleted: true });
      } else {
        setState({
          ...state,
          validationMessage:
            result?.message || 'Please, click on checkbox in captcha',
          requestStatus: 2,
        });
      }
    };

    updateCaptcha({ x, y, code: value.code }, callback);
  };

  useEffect(() => {
    if (state.requestStatus === 0) {
      const callback = result => {
        if (result?.code) {
          setState({
            ...state,
            x: result.x,
            y: result.y,
            validationMessage: '',
            requestStatus: 2,
          });
          onChange({ code: result.code, isCompleted: false });
        } else {
          setState({
            ...state,
            validationMessage: 'Network error',
            requestStatus: 3,
          });
          onChange({ code: '', isCompleted: false });
        }
      };

      setState({
        ...state,
        requestStatus: 1,
        validationMessage: '',
      });
      createCaptcha(
        { checkboxHeight, checkboxWidth, fieldHeight, fieldWidth },
        callback,
      ).catch(() => {});
    }
  }, [checkboxHeight, checkboxWidth, createCaptcha, fieldHeight, fieldWidth, onChange, state]);

  useEffect(() => {
    if (field.current && value.code) {
      const ctx = field.current.getContext('2d');
      ctx.clearRect(0, 0, field.current.width, field.current.height);
      ctx.strokeStyle = 'rgba(255, 255, 255, 1)';
      ctx.lineWidth = 2;
      roundedRect(
        ctx,
        state.x + checkboxWidth / 2,
        state.y + checkboxHeight / 2,
        checkboxWidth,
        checkboxHeight,
        4,
      );
      if (value.isCompleted === true) {
        ctx.strokeStyle = 'rgba(0, 255, 0, 1)';
        ctx.beginPath();
        ctx.moveTo(state.x + 4, state.y + 6);
        ctx.lineTo(state.x + 8, state.y + checkboxHeight - 4);
        ctx.lineTo(state.x + checkboxWidth - 4, state.y + 4);
        ctx.stroke();
      }
    }
  }, [checkboxHeight, checkboxWidth, state.x, state.y, value.code, value.isCompleted]);

  return (
    <div className={ownClassName}>
      <label className={styles['captcha-label']} htmlFor="captchaField">
        I am not a robot
      </label>
      <canvas
        id="captchaField"
        width={fieldWidth}
        height={fieldHeight}
        className={styles['captcha-field']}
        ref={field}
        onClick={handleClick}
      />
    </div>
  );
};

Captcha.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    code: PropTypes.string,
    isCompleted: false,
  }),
  fieldWidth: PropTypes.number,
  fieldHeight: PropTypes.number,
  checkboxWidth: PropTypes.number,
  checkboxHeight: PropTypes.number,
  onChange: PropTypes.func,
  createCaptcha: PropTypes.func,
  updateCaptcha: PropTypes.func,
};

Captcha.defaultProps = {
  className: '',
  value: { code: '', isCompleted: false },
  fieldWidth: 0,
  fieldHeight: 0,
  checkboxWidth: 0,
  checkboxHeight: 0,
  onChange: () => {},
  createCaptcha: () => {},
  updateCaptcha: () => {},
};

export default Captcha;
