import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import List from '../../../core/components/List';
import GalleryItem from './shared/GalleryItem';
import DefaultContent from '../DefaultContent';
import { isFilledArray } from '../../../core/utils/common-utils';

import styles from './AdminHome.module.scss';

const AdminHome = ({ entitiesData }) => {
  const entitiesList = useMemo(() => {
    const items = isFilledArray(entitiesData) ? (
      entitiesData.map(({ id }) => <GalleryItem key={id} entityId={id} />)
    ) : (
      <DefaultContent />
    );

    return <List className={styles['gallery']}>{items}</List>;
  }, [entitiesData]);

  return <section className={styles['admin-home']}>{entitiesList}</section>;
};

AdminHome.propTypes = {
  entitiesData: PropTypes.arrayOf(PropTypes.shape({})),
};

AdminHome.defaultProps = {
  entitiesData: [],
};

export default AdminHome;
