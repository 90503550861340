import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './MenuDivider.module.scss';

const MenuDivider = ({ className }) => {
  const ownClassName = classNames(
    styles['menu-divider'],
    className && className,
  );

  return <li className={ownClassName} />;
};

MenuDivider.propTypes = {
  className: PropTypes.string,
};

MenuDivider.defaultProps = {
  className: '',
};

export default MenuDivider;
