import {
  acLogout,
  acRequestError,
  acResetApp,
  acRequestStart,
  acRequestSuccess,
  acAddMessage,
  acSetEntityData,
} from '../../../core/store/actions';
import { ENTITY_ID } from '../../../constants';
import { MESSAGES_TYPE } from '../../../core/constants';
import { getNetworkError } from '../../../core/utils/network-requests';
import { getSessionToken } from '../../../core/store/selectors';
import { getActiveUser, getUsersData } from '../../selectors';

const { ERROR } = MESSAGES_TYPE;

const { ENTITIES, USERS, ACTIVE_USER } = ENTITY_ID;

const GET_ENTITY = {
  [ENTITIES]: (api, sessionToken) =>
    api.game.entities.getEntities(sessionToken),
  [USERS]: (api, sessionToken) => api.game.users.getUsers(sessionToken),
  [ACTIVE_USER]: (api, sessionToken, login) =>
    api.game.users.getUserByLogin(sessionToken, login),
};

const createGetEntityData = entityId => (...rest) => async (
  dispatch,
  getState,
  api,
) => {
  try {
    dispatch(acRequestStart(entityId));

    const sessionToken = getSessionToken(getState());
    const entityData = await GET_ENTITY[entityId](api, sessionToken, ...rest);

    dispatch(acRequestSuccess(entityId, entityData));
  } catch (err) {
    const networkError = getNetworkError(err);
    dispatch(acRequestError(entityId, networkError));
    if (err.status === 401) {
      dispatch(acLogout());
      dispatch(acResetApp());
    }
    dispatch(acAddMessage({ ...networkError, type: ERROR }));
  }
};

export const acGetEntities = createGetEntityData(ENTITIES);

export const acGetUsers = createGetEntityData(USERS);

export const acGetActiveUser = createGetEntityData(ACTIVE_USER);

export const acUpdateUser = user => async (dispatch, getState, api) => {
  try {
    dispatch(acRequestStart(ENTITY_ID.ACTIVE_USER));
    const state = getState();
    const sessionToken = getSessionToken(state);
    const result = await api.game.users.updateUser(sessionToken, user);
    const users = getUsersData(state).map(item => ({
      ...item,
      ...(item.login === user.login && {
        is_blocked: result.is_blocked,
        disabled: result.disabled,
      }),
    }));

    dispatch(acSetEntityData(ENTITY_ID.USERS, users));
    dispatch(acRequestSuccess(ENTITY_ID.ACTIVE_USER, result));
  } catch (err) {
    const networkError = getNetworkError(err);
    dispatch(acRequestError(ENTITY_ID.ACTIVE_USER, networkError));
    if (err.status === 401) {
      dispatch(acLogout());
      dispatch(acResetApp());
    }
    dispatch(acAddMessage({ ...networkError, type: ERROR }));
  }
};

export const acChangeUserStatus = () => (dispatch, getState, api) => {
  const activeUser = getActiveUser(getState());
  const isInactive = activeUser.is_blocked || activeUser.disabled;
  const updatedUser = {
    ...activeUser,
    ...(isInactive && {
      is_blocked: 0,
      disabled: 0,
    }),
    ...(!isInactive && {
      disabled: 1,
    }),
  };

  return acUpdateUser(updatedUser)(dispatch, getState, api);
};
