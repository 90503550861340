import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const UsersIcon = ({ className, width, height }) => (
  <svg
    className={classNames('icon', { className })}
    width={width}
    height={height}
    viewBox="0 0 80 32"
    preserveAspectRatio="xMidYMid meet"
    fill="transparent"
    stroke="currentcolor"
    strokeWidth="2"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="39" cy="8" r="7" />
    <path d="M25 28 A15 10 0 0 1 55 28 M25 28 A15 3 0 0 0 55 28" />
    <circle cx="12" cy="10" r="5" />
    <path d="M1 26 A10.7 10 0 0 1 22 26 M1 26 A15 3 0 0 0 22 26" />
    <circle cx="68" cy="10" r="5" />
    <path d="M58 26 A10.7 10 0 0 1 79 26 M58 26 A15 3 0 0 0 79 26" />
  </svg>
);

UsersIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

UsersIcon.defaultProps = {
  className: '',
  height: 32,
  width: 80,
};

export default UsersIcon;
