import {
  AC_ADD_MODAL,
  AC_CLOSE_ALL_MODALS,
  AC_REMOVE_MODAL,
  AC_REMOVE_LAST_MODAL,
  AC_RESET_APP,
  AC_SET_MODAL_STATE,
} from '../actions/action-types';

const initialState = {
  modalsList: [],
};

const modalReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case AC_ADD_MODAL: {
      const i = state.modalsList.findIndex(({ id }) => id === payload.id);
      const modalsList =
        i === -1 ? [{ ...payload }, ...state.modalsList] : state.modalsList;

      return {
        ...state,
        modalsList,
      };
    }

    case AC_REMOVE_MODAL: {
      const modalsList = state.modalsList.filter(({ id }) => id !== payload);

      return {
        ...state,
        modalsList,
      };
    }

    case AC_REMOVE_LAST_MODAL: {
      const modalsList = state.modalsList.filter((_, i) => i > 0);

      return {
        ...state,
        modalsList,
      };
    }

    case AC_CLOSE_ALL_MODALS:
      return {
        ...state,
        modalsList: [],
      };

    case AC_SET_MODAL_STATE: {
      const { id, ...rest } = payload;
      const modalsList = state.modalsList.map(item => {
        return id === item.id ? { ...item, ...rest } : item;
      });

      return {
        ...state,
        modalsList,
      };
    }

    case AC_RESET_APP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default modalReducer;
