import { get } from 'lodash';
import { createSelector } from 'reselect';

import { isFilledString } from '../../utils/common-utils';
import { MODELS } from '../../constants';

const getLocationSearch = state => get(state, MODELS.LOCATION_SEARCH, '');

const getLocationPathname = state => get(state, MODELS.LOCATION_PATHNAME, '');

const getUrlParams = createSelector([getLocationSearch], search => {
  const params = {};
  const str = search.split('?')[1];
  if (isFilledString(str)) {
    str.split('&').forEach(param => {
      const i = param.indexOf('=');
      const key = i > 0 ? param.substring(0, i) : '';
      const value =
        i < param.length - 1 ? param.substring(i + 1, param.length) : '';
      if (key && value) {
        params[key] = value;
      }
    });
  }

  return params;
});

export { getLocationSearch, getLocationPathname, getUrlParams };
