import React, { useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';

import UsersList from './shared/UsersList';
import UserForm from './shared/UserForm';
import eventEmitter from '../../../core/components/App/shared/event-emitter';
import { EVENTS, COMMON } from '../../../core/constants';

import styles from './AdminUsers.module.scss';

const { RESIZE } = EVENTS;
const { REM } = COMMON;

const AdminUsers = () => {
  const section = useRef(null);

  const handleResize = () => {
    if (section.current) {
      const screenHeight = document.body.offsetHeight;
      const sectionTop = section.current.getBoundingClientRect().top;
      const sectionHeight = screenHeight - sectionTop - REM;
      section.current.style.height = `${sectionHeight}px`;
    }
  };

  useEffect(() => {
    eventEmitter.on(RESIZE, handleResize);
    handleResize();
  }, []);

  return (
    <section className={styles['admin-users']} ref={section}>
      <UsersList />
      <UserForm />
    </section>
  );
};

AdminUsers.propTypes = {};

export default AdminUsers;
