import {
  AC_REQUEST_START,
  AC_REQUEST_ERROR,
  AC_REQUEST_SUCCESS,
  AC_SET_ENTITY_DATA,
} from '../action-types';

export const acRequestStart = entityId => ({
  type: AC_REQUEST_START,
  payload: { entityId },
});

export const acRequestError = (entityId, error) => ({
  type: AC_REQUEST_ERROR,
  payload: { entityId, error },
});

export const acRequestSuccess = (entityId, data) => ({
  type: AC_REQUEST_SUCCESS,
  payload: {
    entityId,
    ...(data && { data }),
  },
});

export const acSetEntityData = (entityId, data) => ({
  type: AC_SET_ENTITY_DATA,
  payload: {
    entityId,
    data,
  },
});
