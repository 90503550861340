import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '../../../../../core/components/List/shared/ListItem';
import { ROUTES } from '../../../../../constants';

import styles from './GalleryItem.module.scss';
import goToPageWithAuthParams from '../../../../../core/utils/go-to-page-with-auth-params';

const GalleryItem = ({ entityId }) => {
  const route = ROUTES.find(item => item.entityId === entityId);
  const url = route?.url || '';

  return (
    route && (
      <ListItem className={styles['gallery-item']}>
        <a
          href={url}
          className={styles['gallery-item__link']}
          onClick={goToPageWithAuthParams(url)}
        >
          <span className={styles['gallery-item__icon-wrapper']}>
            <route.menuIcon width="90%" height="100%" />
          </span>
          <span className={styles['gallery-item__label-wrapper']}>
            {route.menuLabel}
          </span>
        </a>
      </ListItem>
    )
  );
};

GalleryItem.propTypes = {
  entityId: PropTypes.string.isRequired,
};

GalleryItem.defaultProps = {};

export default GalleryItem;
