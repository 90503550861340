import { createSelector } from 'reselect';
import { get } from 'lodash';

import { ENTITY_ID, MODELS } from '../../constants';

import coreConfig from '../../../.corerc';

const {
  loadStatusSelectorsList: gameLoadStatusSelectorsList = [],
  saveStatusSelectorsList: gameSaveStatusSelectorsList = [],
} = coreConfig;

const simpleSelector = model => state => get(state, model);

const loadStatusSelectorsList = Object.values(ENTITY_ID)
  .map(prefix => simpleSelector(`${MODELS.NETWORKING}.${prefix}LoadStatus`))
  .concat(gameLoadStatusSelectorsList);

const saveStatusSelectorsList = Object.values(ENTITY_ID)
  .map(prefix => simpleSelector(`${MODELS.NETWORKING}.${prefix}SaveStatus`))
  .concat(gameSaveStatusSelectorsList);

export const getIsFetching = createSelector(
  [...loadStatusSelectorsList, ...saveStatusSelectorsList],
  (...statusList) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const status of statusList) {
      if (status === 1) {
        return true;
      }
    }

    return false;
  },
);
