import auth from './auth-api';

import captcha from './captcha-api';

import coreConfig from '../../.corerc';

const { api: game } = coreConfig;

const coreApi = { auth, captcha, game };

export default coreApi;
