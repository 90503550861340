import { get } from 'lodash';
// import { createSelector } from 'reselect';

import { MODELS } from '../../constants';
// import { getUsersData } from './networking-selectors';
// import { isFilledArray } from '../../core/utils/common-utils';

export const getActiveUserLogin = ({ game }) =>
  get(game, MODELS.USERS_ACTIVE_LOGIN);

/* export const getActiveUser = createSelector(
  [getUsersData, getActiveUserLogin],
  (usersData, activeUserLogin) => {
    return isFilledArray(usersData)
      ? usersData.find(({ login }) => login === activeUserLogin)
      : null;
  },
); */

export const getActiveUser = state => get(state, MODELS.ACTIVE_USER);
