import { connect } from 'react-redux';

import NetworkService from './NetworkService';
import { getIsLogged } from '../../../core/store/selectors';
import {
  getActiveUserLogin,
  getEntitiesShouldBeLoaded,
  getUsersShouldBeLoaded,
} from '../../../store/selectors';
import {
  acGetActiveUser,
  acGetEntities,
  acGetUsers,
} from '../../../store/actions';
import { acSetEntityData } from '../../../core/store/actions';
import { ENTITY_ID } from '../../../constants';

const mapStateToProps = state => ({
  isLogged: getIsLogged(state),
  entitiesShouldBeLoaded: getEntitiesShouldBeLoaded(state),
  usersShouldBeLoaded: getUsersShouldBeLoaded(state),
  activeUserLogin: getActiveUserLogin(state),
});

const mapDispatchToProps = {
  getEntities: acGetEntities,
  getUsers: acGetUsers,
  getActiveUser: acGetActiveUser,
  clearActiveUser: () => acSetEntityData(ENTITY_ID.ACTIVE_USER, null),
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkService);
