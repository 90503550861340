import { AC_SELECT_USER } from '../actions/action-types';
import { AC_RESET_APP } from '../../core/store/actions/action-types';

const initialState = {
  activeUserLogin: '',
};

const usersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AC_SELECT_USER:
      return {
        ...state,
        activeUserLogin: payload,
      };

    case AC_RESET_APP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default usersReducer;
