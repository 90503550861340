import { connect } from 'react-redux';

import UserForm from './UserForm';
import { getIsDesktop } from '../../../../../core/store/selectors';
import { getActiveUser } from '../../../../../store/selectors';
import { acChangeUserStatus, acSelectUser } from '../../../../../store/actions';

const mapStateToProps = state => ({
  isDesktop: getIsDesktop(state),
  value: getActiveUser(state),
});

const mapDispatchProps = {
  changeUserStatus: acChangeUserStatus,
  unselectUser: () => acSelectUser(''),
};

export default connect(mapStateToProps, mapDispatchProps)(UserForm);
