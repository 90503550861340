import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import List from '../../../../../core/components/List';
import ListItem from '../../../../../core/components/List/shared/ListItem';
import DefaultContent from '../../../DefaultContent';
import { isFilledArray } from '../../../../../core/utils/common-utils';

import styles from './UsersList.module.scss';

const UsersList = ({ activeUserLogin, usersData, selectUser }) => {
  const handleUserSelect = useCallback(
    userLogin => () => selectUser(userLogin), [selectUser]);

  const usersList = useMemo(() => {
    return isFilledArray(usersData) ? (
      <List className={styles['users-list']}>
        {usersData.map(({ login, disabled, is_blocked: isBlocked }) => {
          const isDisabled = disabled === 1 || isBlocked === 1;
          const listItemClassName = classNames(
            styles['users-list-item'],
            activeUserLogin === login && styles['users-list-item--selected'],
          );

          return (
            <ListItem
              key={login}
              className={listItemClassName}
              onClick={handleUserSelect(login)}
            >
              {login}
              {isDisabled && (
                <span className={styles['users-list-item__status']}>
                  Inactive
                </span>
              )}
            </ListItem>
          );
        })}
      </List>
    ) : (
      <DefaultContent />
    );
  }, [usersData, activeUserLogin, handleUserSelect]);

  return <aside className={styles['users-list-root']}>{usersList}</aside>;
};

UsersList.propTypes = {
  activeUserLogin: PropTypes.string,
  usersData: PropTypes.arrayOf(PropTypes.shape({})),
  selectUser: PropTypes.func,
};

UsersList.defaultProps = {
  activeUserLogin: '',
  usersData: [],
  selectUser: () => {},
};

export default UsersList;
