import { connect } from 'react-redux';

import AdminHome from './AdminHome';
import { getEntitiesData } from '../../../store/selectors';

const mapStateToProps = state => ({
  entitiesData: getEntitiesData(state),
});

export default connect(mapStateToProps)(AdminHome);
