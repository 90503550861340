import UsersIcon from '../core/icons/UsersIcon';

const ROUTES = [
  {
    id: 'ADMIN',
    url: '/',
    menuLabel: 'Admin',
    title: 'Admin',
    pageTitle: 'Feature - Admin',
  },
  {
    id: 'ADMIN_USERS',
    url: '/users',
    menuLabel: 'Users',
    menuIcon: UsersIcon,
    title: 'Users',
    pageTitle: 'Feature - Admin - Users',
    entityId: 'users',
  },
];

export default ROUTES;
