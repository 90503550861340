import { isFilledArray } from './common-utils';

/**
 * returns children of menu item by item`s id
 * @param {Array} list
 * @param {string} parentId
 * @returns {Array} Children of item by item`s id
 */
const getChildrenByParentId = (list, parentId) => {
  if (!parentId) {
    return list;
  }

  let result = [];

  if (isFilledArray(list)) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === parentId) {
        result = isFilledArray(list[i].children) ? list[i].children : [];
      } else if (Array.isArray(list[i].children)) {
        result = getChildrenByParentId(list[i].children, parentId);
      }
      if (isFilledArray(result) || list[i].id === parentId) {
        break;
      }
    }
  }

  return result;
};

export default getChildrenByParentId;
