import goToPageWithAuthParams from '../../../core/utils/go-to-page-with-auth-params';

const getMainMenuOptions = ({
  // isLogged,
  // parentId,
  // closeAllModals,
  // goTo,
  // openModal,
  ...rest
}) => {
  const { pathname } = rest;

  return [
    {
      id: 'adminHome',
      label: 'Admin home',
      isVisible: pathname !== '/',
      onClick: goToPageWithAuthParams('/'),
    },
    {
      id: 'adminUsers',
      label: 'Admin users',
      isVisible: pathname !== '/users',
      onClick: goToPageWithAuthParams('/users'),
    },
  ];
};

export default getMainMenuOptions;
